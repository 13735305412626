import styled from 'styled-components'
import { Dropdown } from 'semantic-ui-react'

const StyledDropdown = styled(Dropdown).withConfig({ componentId: 'dmi-ui-dropdown' })`
  &.ui.dropdown {
    border-color: ${({ theme }) => theme.colorFormBorder} !important;
    border-radius: 0 !important;
    font-size: ${({ theme }) => theme.inputFontSize};
    font-weight: normal;
    padding: 10px;
    line-height: unset;

    & > div:first-child {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 87%;
      display: block !important;
    }

    .menu {
      border: 1px solid ${({ theme }) => theme.colorFormBorder} !important;
      border-radius: 0 !important;

      .item {
        padding: 15px !important;
        font-size: ${({ theme }) => theme.inputFontSize};

        &.active.selected {
          font-weight: normal;
        }
      }
    }

    &.ui.selection.dropdown,
    &.ui.selection.active.dropdown:hover {
      box-shadow: none;
    }

    &.ui.selection.dropdown .menu {
      border: 1px solid ${({ theme }) => theme.colorFormBorder} !important;
    }

    &.ui.selection.dropdown > .delete.icon,
    &.ui.selection.dropdown > .dropdown.icon,
    &.ui.selection.dropdown > .search.icon {
      top: 6px;
      right: 8px;
      font-size: 20px;
    }

    &.max-content {
      .menu {
        width: max-content;
      }
    }

    &.transparent {
      background: none !important;
      border: none !important;
      padding-left: 0 !important;

      & > .default.text,
      & > .text {
        font-weight: 700 !important;
        color: ${({ theme }) => theme.colorBody} !important;
        font-size: ${({ theme }) => theme.bodySize};
      }
    }
  }
`

export default StyledDropdown
