import Router from 'next/router'
import { clearUserTokens, getAccessToken, getCookie, setCookie, sessionAccessToken } from '@utils/storage'
import { findObjByKey, logger } from '@utils'
import { api, User as UserService } from '@services'
import Error from '../pages/_error'
import React from 'react'
import { AppContext } from 'next/app'

const withUserAuth = App =>
  class extends React.Component {
    static displayName = `withUserAuth(${App.displayName})`

    static async getInitialProps(ctx: AppContext['ctx']) {
      const { req, res, asPath } = ctx
      let showErrorPage = false
      const pages = { signin: '/signin', menu: '/menu', index: '/', error: '/error' }
      let userRoles = {}
      const sessionToken = await sessionAccessToken()

      const accessToken = () => {
        const token = getAccessToken(req)
        let tokenValue = null
        if (typeof token !== 'undefined' && token !== null && token !== '' && token !== '-') {
          tokenValue = token
        } else {
          tokenValue = sessionToken
        }
        return tokenValue
      }

      const redirect = (url) => {
        if (res) {
          // @ts-ignore, This is "old" way of doing things, must be changed
          res.redirect(url)
          res.end()
        } else if ((typeof window !== 'undefined') && url) {
          Router.push(url)
        }
      }

      // Because some sections have parts in react and parts in legacy, and therefore, different URL's
      // we need to check also if property alternative URL matches if no section matches by primary URL
      const findSectionByAlternativeUrl = (sections, url) => {
        let objByKey = null
        Object.keys(sections).forEach((section) => {
          if (sections[section].alternativeUrls) {
            sections[section].alternativeUrls.forEach((alternativeUrl) => {
              if (alternativeUrl === url) {
                objByKey = sections[section]
              }
            })
          }
        })
        return objByKey
      }

      const hasSectionAuth = (data) => {
        const { sections } = getEnvConfig.cms
        let url = `/${asPath.split('/')[1]}`
        url = url.split('?')[0]
        const sectionDefinitionForCurrentUrl = findObjByKey(sections, 'url', url)
          || findSectionByAlternativeUrl(sections, url)
          || findObjByKey(sections, 'url', `/admin${url}`)
          || null

        const sectionRequiredRoles = sectionDefinitionForCurrentUrl ? sectionDefinitionForCurrentUrl.roles : []
        // User has access to the section if there is any intersection between his roles and the section roles.
        const hasSectionAccess = data.filter(value => sectionRequiredRoles.includes(value)).length > 0
        const logoutUser = () => {
          clearUserTokens(req)
          redirect(pages.signin)
        }

        if (!sectionDefinitionForCurrentUrl) {
          logoutUser()
        }
        if (!hasSectionAccess) {
          logoutUser()
        }
      }

      const hasOneSectionAuth = (data) => {
        const { sections } = getEnvConfig.cms
        const userSections = []
        Object.keys(sections).forEach((section) => {
          const sectionRole = sections[section].roles[0].toString()
          const sectionAccess = data.indexOf(sectionRole) >= 0
          if (sectionAccess) {
            userSections.push(sections[section])
          }
        })
        if (userSections.length === 1) {
          redirect(userSections[0].url)
        }
      }

      if (res) {
        if (asPath === pages.index) {
          redirect(pages.menu)
        }
        if (asPath !== pages.signin && !accessToken()) {
          redirect(pages.signin)
        }
      }

      if (accessToken()) {
        api.setHeader('Authorization', `Bearer ${accessToken()}`)
        if (!getCookie(null, 'fullName')) {
          try {
            const meData = await UserService.getMe()
            if ('data' in meData) {
              setCookie('fullName', JSON.stringify(meData.data.fullName), { path: '/' })
            }
          } catch (e) {
            logger(e)
          }
        }
        try {
          const headers = { Authorization: `Bearer ${accessToken()}` }
          const fetchRoles = await UserService.getLogin(headers)
            if (fetchRoles && fetchRoles.data && Array.isArray(fetchRoles.data.roles)) {
            userRoles = fetchRoles.data.roles
            // NOTE: This line will we removed when we get user role for Push Notification from BE
            // we are forcefully push user role for notification here
            // userRoles.push('cmsPushNotificationEditor')
            if (asPath !== pages.index && asPath !== pages.menu && asPath !== pages.signin) {
              hasSectionAuth(userRoles)
            } else if (asPath === pages.menu) {
              hasOneSectionAuth(userRoles)
            }
          } else {
            clearUserTokens(req)
            showErrorPage = true
            redirect(pages.error)
          }
        } catch (e) {
          logger(e)
        }
      }

      return {
        userRoles,
        pathname: asPath,
        showErrorPage,
        ...(App.getInitialProps ? await App.getInitialProps(ctx) : {})
      }
    }

    // Keeping commented and remove once line 159 is clarified
    // static propTypes = {
    //   showErrorPage: PropTypes.bool
    // }

    render() {
      const { props } = this
      return (
        <>
          {/* @ts-ignore --> Identify how this works and fix TS */}
          {props.showErrorPage ? <Error /> : <App {...props} />}
        </>
      )
    }
  }

export default withUserAuth