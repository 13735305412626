import styled from 'styled-components'
import { Breadcrumb } from 'semantic-ui-react'

const StyledBreadcrumb = styled(Breadcrumb).withConfig({ componentId: 'dmi-ui-breadcrumb' })`
  &.ui.breadcrumb {
    display: flex;
    a, div {
      color: inherit;
      font-size: ${({ theme }) => theme.bodySize};
      line-height: ${({ theme }) => theme.bodyLineHeight};

      &.section {
        display: flex;
        margin: auto 0;
        padding-right: 11.25px;

        &:first-child {
          padding-right: 10px;
          padding-left: 0;
        }
      }
    }
    a {
      &.section {
        font-weight: bold;
      }
    }

    .section {
      margin: auto 0;
      padding-left: 11.25px;
      font-weight: normal;
      font-size: ${({ theme }) => theme.bodySize};
      line-height: ${({ theme }) => theme.bodyLineHeight};
    }

    .icon-squares,
    .icon-arrow {
      width: 24px;
      height: 24px;
    }

    .icon-squares {
      margin-right: 3px;
    }
  }
`

export default StyledBreadcrumb
