import PropTypes from 'prop-types'
import { Container } from 'semantic-ui-react'
import styled from 'styled-components'

const ContainerComponent = ({ children, ...props }) => (
  <Container {...props}>
    {children}
  </Container>
)

ContainerComponent.propTypes = {
  children: PropTypes.node
}

const StyledContainerComponent = styled(ContainerComponent).withConfig({ componentId: 'dmi-ui-container' })`
  &.ui.container {
    @media only screen and (min-width: ${({ theme }) => theme.largeMonitorBreakpoint}) {
      width: ${({ theme }) => theme.desktopContainer};
    }
  }
`

export default StyledContainerComponent
