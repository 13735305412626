import styled from 'styled-components'
import { Input } from 'semantic-ui-react'

const StyledInput = styled(Input).withConfig({ componentId: 'dmi-ui-input' })`
&.ui.input {
  width: 100%;

  & > input {
    border-radius: 0;
    border: 1px solid ${({ theme }) => theme.colorFormBorder};
    color: ${({ theme }) => theme.colorDark};
    font-weight: 400;
    font-size: ${({ theme }) => theme.inputFontSize};
    font-family: ${({ theme }) => theme.bodyFont};
    padding: 13px 0 13px 13px;

    &::placeholder {
      color: ${({ theme }) => theme.colorPlaceholder};
      opacity: 1;
    }

    &::-ms-clear {
      display: none;
    }
  }

  &.disabled {
    opacity: unset;

    & > input {
      font-size: ${({ theme }) => theme.inputFontSize} !important;
      background: ${({ theme }) => theme.colorContent};
      border: 1px solid ${({ theme }) => theme.colorFormBorder};
      color: ${({ theme }) => theme.colorDark};
      opacity: unset;
    }
  }
}
`

export default StyledInput
