import styled from 'styled-components'
import { Header } from 'semantic-ui-react'

const fontSize = {
  h1: '24px',
  h2: '22px',
  h3: '20px',
  h4: '18px',
  h5: '16px',
  h6: '15px'
}

export default styled(Header).withConfig({ componentId: 'dmi-ui-header' })`
  font-size: ${({ as }) => (as ? fontSize[as] : 'inherit')};
`
