import PropTypes from 'prop-types'
import styled from 'styled-components'
import { TextArea } from 'semantic-ui-react'

import Caption from './Caption'

const CustomTextarea = ({ countdownValue, ...props }) => (
  <>
    <TextArea {...props} />
    {countdownValue && <Caption value={countdownValue} />}
  </>
)

const StyledTextarea = styled(CustomTextarea).withConfig({ componentId: 'dmi-ui-textarea' })`
  &.dmi-ui-textarea {
    box-shadow: none !important;
    width: 100% !important;
    border-radius: 0 !important;
    border: 1px solid ${props => props.theme.colorFormBorder} !important;
    color: ${props => props.theme.colorDark} !important;
    font-weight: 400;
    font-size: ${({ theme }) => theme.inputFontSize} !important;
    font-family: ${props => props.theme.bodyFont} !important;
    padding: 13px 0 13px 13px !important;
  }
`
CustomTextarea.propTypes = {
  countdownValue: PropTypes.string
}

export default StyledTextarea
