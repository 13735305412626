import PropTypes from 'prop-types'
import styled from 'styled-components'

// Reusable Caption for text below images, textareas, or any other element
const Caption = ({ value, ...props }) => <div {...props}>{value}</div>

const StyledCaption = styled(Caption).withConfig({ componentId: 'dmi-ui-caption' })`
  font-size: 13px;
  color: ${props => props.theme.colorCaption};
  text-align: right;
`

Caption.propTypes = {
  value: PropTypes.string
}

export default StyledCaption
