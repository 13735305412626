import PropTypes from 'prop-types'
import { Button, Ref } from 'semantic-ui-react'
import { createGlobalStyle } from 'styled-components'

const Styles = createGlobalStyle`
  .ui.button {
    border-radius: 0;
    font-family: ${props => props.theme.bodyFont};
    font-weight: 700;
    font-size: ${props => (props.size ? 'inherit' : props.theme.buttonFontSize)};

    &.is-narrow {
      padding-left: 40px;
      padding-right: 40px;
    }

    &.is-large {
      line-height: 45px;
      height: 45px;
      padding: 0 40px !important;
      font-size: ${props => props.theme.buttonFontSize};
    }

    &:disabled {
      opacity: .2 !important;
    }
  }
`

const ButtonWithRefAndStyles = React.forwardRef((props, ref) => (
  <>
    <Ref innerRef={ref}>
      <Button {...props} />
    </Ref>
    <Styles />
  </>
))

ButtonWithRefAndStyles.propTypes = {
  children: PropTypes.node
}

export default ButtonWithRefAndStyles
