import PropTypes from 'prop-types'
import { Tab } from 'semantic-ui-react'
import styled from 'styled-components'

// disabledTabs is only used in the styled component, so a bit of trick
// until I learn workaround for Chrome message
// eslint-disable-next-line no-unused-vars
const TabComponent = ({ children, disabledTabs, ...props }) => (
  <Tab {...props}>
    {children}
  </Tab>
)

TabComponent.propTypes = {
  children: PropTypes.node,
  disabledTabs: PropTypes.array
}

const disableTabs = (tabs) => {
  let code = ''
  tabs.forEach((tabNum) => {
    code += `&:nth-of-type(${tabNum}) {
        color: rgba(40,40,40,.3);
        pointer-events: none;
      }`
  })
  return code
}

const StyledTabComponent = styled(TabComponent).withConfig({ componentId: 'dmi-ui-container' })`
  .ui.secondary.pointing.menu {
    margin-top: 10px;
    font-size: 15px;
    border-bottom: 1px solid rgba(34,36,38,.15);

    .item {
      padding-left: 0;
      padding-right: 0;
      margin-right: 20px;
      padding-bottom: 21px;

      &.active {
        font-weight: normal;
        border-bottom-width: 3px;
        margin-bottom: 0px;
        padding-bottom: 18px;
      }
      // Unfortunately, the Tabs component doesn't allow to disable tabs. This code is a workaround to that limitation
      ${props => props.disabledTabs && disableTabs(props.disabledTabs)}
    }
  }
`

export default StyledTabComponent
