import PropTypes from 'prop-types'
import styled from 'styled-components'

const ValidationError = ({ className, text }) => <p className={className}>{text}</p>

const StyledValidationError = styled(ValidationError).withConfig({ componentId: 'dmi-ui-validation-error' })`
  margin-top: 5px;
  color: ${({ theme }) => theme.colorValidationError};
  font-size: ${({ theme }) => theme.validationErrorFontSize};
`

ValidationError.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string
}

export default StyledValidationError
